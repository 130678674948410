.Projects h2 {
  text-align: center;
  color: #0dcaf0;
  font-size: 56px;
  margin-bottom: 50px;
}
.Projects h3 {
  font-size: 24px;
  margin: 10px;
}

.Projects img {
  max-width: 100%;
  border-radius: 10%;
}

.Projects img:hover {
  transform: scale(1.5);
}

.Projects p {
  font-size: 18px;
  margin: 20px 0 50px 0;
}

@media (max-width: 400px) {
  .Projects h2 {
    font-size: 40px;
  }

  .Projects h3 {
    font-size: 28px;
  }

  .Projects img {
    max-width: 80%;
  }

  .Projects img:hover {
    transform: scale(1.5);
  }

  .Projects p {
    font-size: 18px;
  }
}

@media (max-width: 900px) and (min-width: 401px) {
  .Projects h3 {
    font-size: 19px;
  }

  .Projects p {
    font-size: 11px;
  }
}
