h2 {
  text-align: left;
  color: #0dcaf0;
  margin: 0 30px 30px;
  font-size: 56px;
}

.FillingFormName,
.FillingFormEmail,
.FillingFormMessage {
  margin: 0 100px;
}

button.btn {
  margin: 30px 0 50px;
  border-radius: 50%;
  padding: 25px;
  transition: all 200ms ease-in-out;
}

button.btn:hover {
  background: #01ffdd;
  cursor: pointer;
}

@media (max-width: 400px) {
  h2 {
    text-align: center;
    font-size: 40px;
  }

  .form-label {
    position: relative;
    left: -130px;
    font-weight: bold;
    font-size: 16px;
  }
}

@media (max-width: 900px) and (min-width: 401px) {
  .form-label {
    position: relative;
    left: -327px;
    font-weight: bold;
    font-size: 20px;
  }
}
