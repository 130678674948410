.grid-2-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

.About h2 {
  text-align: center;
  color: #0dcaf0;
  margin-bottom: 30px;
  font-size: 56px;
}

.About .btn {
  display: block;
  margin: 100px;
  border-radius: 50%;
  padding: 25px;
  transition: all 200ms ease-in-out;
}

.About .btn:hover {
  background: #01ffdd;
  cursor: pointer;
}

.About p {
  text-align: left;
  line-height: 1.5;
}

.About img {
  max-width: 100%;
  border-radius: 50%;
}

@media (max-width: 400px) {
  .About h2 {
    font-size: 40px;
  }

  .About p {
    text-align: inherit;
    font-size: 16px;
  }

  .About .btn {
    position: relative;
    right: 50px;
    top: -95px;

    margin: 120px;
  }

  .About img {
    display: flex;
    position: absolute;
    left: 250px;
    top: 950px;
    max-width: 30%;
    border-radius: 50%;
  }
}

@media (max-width: 900px) and (min-width: 401px) {
  .About img {
    display: flex;
    position: absolute;
    left: 460px;
    top: 930px;
    max-width: 30%;
    border-radius: 50%;
  }

  .About .btn {
    position: relative;
    right: 20px;
    top: 30px;

    margin: 0 auto;
  }
}
