.contact-info {
  font-size: 36px;
}

i {
  color: #0dcaf0;
}

.grid-4-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

h5 {
  margin: 30px 0;
}

@media (max-width: 400px) {
  h5 {
    font-size: 12px;
    margin: 20px 0;
  }
}

@media (max-width: 900px) and (min-width: 401px) {
  h5 {
    font-size: 17px;
  }
}
